<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('role_filter')" :isNewButton="true" :isColumns="true"
                    @new-button-click="formModalShow('create')" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('role_filter')" :isNewButton="true"
                    :isColumns="true" @new-button-click="formModalShow('create')"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('roles')">
                            <role-selectbox v-model="datatable.queryParams.filter.role_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('permission_id')">
                            <permission-selectbox v-model="datatable.queryParams.filter.permission_id">
                            </permission-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('model')">
                            <model-list-selectbox v-model="datatable.queryParams.filter.model">
                            </model-list-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('operation')">
                            <parameter-selectbox code="role_filter_operation_types" v-model="datatable.queryParams.filter.operation" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('filter_type')">
                            <parameter-selectbox code="role_filter_filter_types" v-model="datatable.queryParams.filter.filter_type" />
                        </b-form-group>
                    </b-col>
             
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="dataFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <data-form @dataFormSuccess="dataFormSuccess" :formId="formId" :formType="formType" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';

// Page
import DataForm from './DataForm';

// Other
import qs from 'qs';
import RoleFilterService from "@/services/RoleFilterService";

export default {
    name: "PageRoleFilter",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        DataForm,
    },
    metaInfo() {
        return {
            title: this.$t('role_filter')
        };
    },
    data() {
        return {
            formId: null,
            formType: "",
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'role_show',
                                callback: (row) => {
                                    this.formModalShow("show", row.id);
                                },
                                show: () => {
                                    return true
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'role_update',
                                hidden: false,
                                callback: (row) => {
                                    this.formModalShow("update", row.id);
                                },
                                show: () => {
                                    return true
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: 'role_delete',
                                hidden: false,
                                callback: (row) => {
                                    this.formModalShow("delete", row.id);
                                },
                                show: () => {
                                    return true
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('explanation'),
                        field: 'role_explanation',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.getLocaleText(row, 'role_explanation')
                        },
                    },
                    {
                        label: `${this.$t('filter_type')}`,
                        field: 'filter_type',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.getStorageKeyToValue("parameter_role_filter_filter_types_not_public", "code", row.filter_type)
                        },
                    },
                    {
                        label: `${this.$t('filter_value')}`,
                        field: 'filter_value',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: `${this.$t('field')}`,
                        field: 'field',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('model'),
                        field: 'model',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('permission'),
                        field: 'permission',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('operation'),
                        field: 'operation',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.getStorageKeyToValue("parameter_role_filter_operation_types_not_public", "code", row.operation)
                        },
                    },
                    {
                        label: this.$t('pathname'),
                        field: 'pathname',
                        sortable: false,
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return RoleFilterService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        delete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then(() => {
                    RoleFilterService.deleteRole(id)
                        .then((response) => {
                            this.filter();
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        });
                });
        },

        // Form Clear
        formClear() {
            this.formId = null
            this.formType = ""
        },
        formModalShow(type = "", id = null) {
            this.formType = type
            this.formId = id
            this.$refs.dataFormModal.$refs.commonModal.show()
        },
        dataFormSuccess() {
            this.$refs.dataFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
    }
}
</script>
