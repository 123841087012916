<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('roles')">
                            <role-selectbox v-model="formData.role_id" :disabled="formDisabled"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="permission_id" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('permission_id')">
                            <permission-selectbox v-model="formData.permission_id" :disabled="formDisabled">
                            </permission-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('model')">
                        <ValidationProvider name="model" rules="required" v-slot="{ valid, errors }">
                            <model-list-selectbox v-model="formData.model" :disabled="formDisabled">
                            </model-list-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('operation')">
                        <ValidationProvider name="operation" rules="" v-slot="{ valid, errors }">
                            <parameter-selectbox code="role_filter_operation_types" v-model="formData.operation"
                                :disabled="formDisabled" :validate-error="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">

                    <b-form-group :label="$t('field')">
                        <ValidationProvider name="field" rules="required" v-slot="{ valid, errors }">
                            <template v-if="!formData.operation">
                                <b-form-input v-model="formData.field" :state="errors[0] ? false : null"
                                    :disabled="formDisabled" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" /></template>
                            <template v-else>
                                <multiselect :class="errors[0] ? 'box-border-color' : ''" :options="systemFields"
                                    :placeholder="$t('field')" v-model="formData.field" :searchable="true"
                                    @input="handleInput($event, false)" :disabled="formDisabled">
                                </multiselect>
                            </template>

                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('filter_type')">
                        <ValidationProvider name="filter_type" rules="required" v-slot="{ valid, errors }">
                            <parameter-selectbox code="role_filter_filter_types" v-model="formData.filter_type"
                                :disabled="formDisabled" :validate-error="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('filter_value')">
                        <ValidationProvider name="filter_value" rules="required" v-slot="{ valid, errors }">
                            <b-form-input v-model="formData.filter_value" :state="errors[0] ? false : null"
                                :disabled="formDisabled" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('pathname')">
                        <ValidationProvider name="pathname" rules="" v-slot="{ valid, errors }">
                            <b-form-input v-model="formData.pathname" :disabled="formDisabled" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button :variant="formType === 'delete' ? 'danger' : 'primary'" @click="save" :disabled="formLoading">
                    {{ okButtonText }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import RoleService from '@/services/RoleService'
import handleInput from "@/helpers/handleInput";

import SystemService from '@/services/SystemService'
import RoleFilterService from '@/services/RoleFilterService'

export default {
    name: "RoleFilterDataForm",
    components: {},
    props: {
        formType: {
            type: String,
            default: ""
        },
        formId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            handleInput,
            formData: {},
            roleOptions: [],
            formLoading: false,
            systemModelList: [],
            systemFields: [],
        }
    },
    watch: {
        'formData.model': {
            handler(val) {
                if (val) {
                    this.getFields(val)
                    return
                }
                this.systemFields = []
                this.formData.field = null
            }
        },
        formType: {
            handler(val) {
                this.formData = {}
                if (val !== "create" && this.formId > 0) {
                    this.getFormData()
                }
            }
        },
    },
    computed: {
        formDisabled() {
            return ["delete", "show"].includes(this.formType)
        },
        okButtonText() {
            switch (this.formType) {
                case "create":
                    return this.$t('create')
                case "show":
                    return this.$t('close')
                case "update":
                    return this.$t('update')
                case "delete":
                    return this.$t('delete')
                default:
                    return this.$t('close')
            }
        }
    },
    methods: {
        async save() {
            let isValid = await this.$refs.formModalValidate.validate()
            if (!isValid && this.formType !== "delete") return

            let response = null;
            this.formLoading = true

            if (this.formType === "delete") {
                const result = await this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                if (!result.isConfirmed) {
                    return
                }
            }

            switch (this.formType) {
                case 'create':
                    response = await RoleFilterService.store(this.formData).catch(e => {
                        this.showErrors(e);
                    })
                    break;
                case 'update':
                    response = await RoleFilterService.update(this.formId, this.formData).catch(e => {
                        this.showErrors(e);
                    })
                    break;
                case 'delete':
                    response = await RoleFilterService.del(this.formId).catch(e => {
                        this.showErrors(e);
                    })
                    break;
            }


            if (response.status === 200) {
                this.$toast.success(this.$t('api.' + response.data.message));
                this.formData = {}
                this.$emit('dataFormSuccess')
                this.$refs.formModalValidate.reset();
            }
            this.formLoading = false
        },
        async getModelList() {
            const response = await SystemService.getModelList()
            if (response?.status === 200) {

                this.systemModelList = response.data.data
            }
        },
        getRoles() {
            this.roleOptions = []

            const config = {
                params: {
                    limit: -1
                }
            }

            RoleService.getAll(config)
                .then((response) => {
                    this.roleOptions = response?.data?.data?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        ?.map((item) => {
                            return {
                                value: item.name,
                                text: this.getLocaleText(item, 'explanation')
                            }
                        }) ?? []

                })
                .catch((e) => {
                    this.showErrors(e)
                })
        },
        async getFormData() {
            const response = await RoleFilterService.get(this.formId).catch(e => {
                this.showErrors(e);
            })
            if (response.status === 200) {
                this.formData = response.data.data.role_filter
            }
        },
        async getFields(model) {
            const response = await SystemService.getModelColumnList(model)
            if (response.status === 200) {
                this.systemFields = response.data.data
            }
        }
    },
    created() {
        this.getRoles()
        // this.getModelList()
    }
}
</script>
